import { PageContainer } from "../PageContainer";
import { PageParticleSection } from "../PageParticleSection";
import React, { ReactNode } from "react";
import Fade from "react-reveal/Fade";

const PageParticleSectionJumbotron = ({
  children,
  transparentHeader = false,
}: {
  children: ReactNode;
  transparentHeader?: boolean;
}) => {
  return (
    <PageParticleSection>
      <PageContainer>
        <div
          className={`${
            transparentHeader ? "pb-24 pt-36" : "py-24"
          } text-white`}
        >
          <Fade top>
            <p className="text-6xl">{children}</p>
          </Fade>
        </div>
      </PageContainer>
    </PageParticleSection>
  );
};

export { PageParticleSectionJumbotron };
