import { SiteNavigation, SiteNavigationProps } from "../SiteNavigation";
import React, { useEffect, useRef, useState } from "react";

enum SiteButtonType {
  PRIMARY = "inline-block items-center text-center rounded-full bg-gopurple px-12 py-4 tracking-wide text-button font-bold uppercase text-white border-2 border-gopurple hover:bg-transparent hover:text-gopurple transition-colors",
  INVERTED = "inline-block items-center text-center rounded-full bg-transparent px-12 py-4 tracking-wide text-button font-bold uppercase text-white border-2 border-white hover:bg-white hover:text-gopurple transition-colors",
  TOPURPLE = "inline-block items-center text-center rounded-full bg-white px-12 py-4 tracking-wide text-button font-bold uppercase text-gopurple border-2 border-white hover:bg-transparent hover:text-white transition-colors",
  TOWHITE = "inline-block items-center text-center rounded-full bg-gopurple px-12 py-4 tracking-wide text-button font-bold uppercase text-white border-2 border-gopurple hover:bg-transparent hover:border-white hover:text-white transition-colors",
  FACEBOOK = "inline-block items-center text-center rounded-full bg-facebook p-4 tracking-wide text-button text-white border-2 border-facebook hover:bg-transparent hover:text-facebook transition-colors",
  TWITTER = "inline-block items-center text-center rounded-full bg-twitter p-4 tracking-wide text-button text-white border-2 border-twitter hover:bg-transparent hover:text-twitter transition-colors",
  LINKEDIN = "inline-block items-center text-center rounded-full bg-linkedin p-4 tracking-wide text-button text-white border-2 border-linkedin hover:bg-transparent hover:text-linkedin transition-colors",
  REDDIT = "inline-block items-center text-center rounded-full bg-reddit p-4 tracking-wide text-button text-white border-2 border-reddit hover:bg-transparent hover:text-reddit transition-colors",
  GOOGLEPLUS = "inline-block items-center text-center rounded-full bg-googleplus p-4 tracking-wide text-button text-white border-2 border-googleplus hover:bg-transparent hover:text-googleplus transition-colors",
  READ = "inline-block items-center text-center rounded-full bg-gopurple px-12 py-2 tracking-wide text-button font-bold uppercase text-white border-2 border-gopurple hover:bg-transparent hover:text-gopurple transition-colors",
}

interface SiteButtonProps {
  type?: SiteButtonType;
}

const SiteButton = ({
  children,
  type = SiteButtonType.PRIMARY,
  ...props
}: SiteNavigationProps & SiteButtonProps) => (
  <SiteNavigation {...props}>
    <div className={type}>{children}</div>
  </SiteNavigation>
);

const SiteButtonButItAttemptsToPreventItselfFromBeingBlocked = ({
  href,
  type = SiteButtonType.PRIMARY,
  children,
  target,
  ...props
}: Omit<SiteNavigationProps, "to" | "onClick"> & SiteButtonProps) => {
  const [isHTMLButton, setIsHTMLButton] = useState(true);
  const ref = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    let animationFrame: number;

    const nextFrame = () => {
      // If we have a hook into the link...
      if (ref.current) {
        // Get the bounding box dimensions
        const bounds = ref.current.getBoundingClientRect();

        if (bounds.width === 0 || bounds.height === 0) {
          setIsHTMLButton(false);
        }
      }

      if (isHTMLButton) animationFrame = requestAnimationFrame(nextFrame);
    };

    animationFrame = requestAnimationFrame(nextFrame);

    return () => {
      cancelAnimationFrame(animationFrame);
    };
  });

  return (
    <a
      {...props}
      ref={ref}
      href={isHTMLButton ? href : undefined}
      tabIndex={0}
      target={target}
      onClick={
        isHTMLButton
          ? undefined
          : () => {
              if (href) {
                if (target) {
                  open(href, target);
                } else {
                  window.location.href = href;
                }
              }
            }
      }
    >
      <div className={`${type} cursor-pointer`}>{children}</div>
    </a>
  );
};

export {
  SiteButton,
  SiteButtonButItAttemptsToPreventItselfFromBeingBlocked,
  SiteButtonType,
};
