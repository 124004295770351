import { BlogPostCard } from "../components/BlogPostCard";
import { PageContainer } from "../components/PageContainer";
import { PageLayout } from "../components/PageLayout";
import { PageParticleSectionJumbotron } from "../components/PageParticleSectionJumbotron";
import { PageSection } from "../components/PageSection";
import { SectionText } from "../components/SectionText";
import { graphql, PageProps } from "gatsby";
import React from "react";

const IndexPage = ({ data }: PageProps<Queries.AllBlogPostQuery>) => {
  return (
    <PageLayout transparentHeader>
      <PageParticleSectionJumbotron transparentHeader>
        Blog
      </PageParticleSectionJumbotron>
      <PageSection>
        <PageContainer>
          <div className="flex gap-2 flex-col my-6">
            {data.allMarkdownRemark.nodes.map((node) => (
              <BlogPostCard content={node} key={node.fields?.slug} />
            ))}
          </div>
        </PageContainer>
      </PageSection>
    </PageLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query AllBlogPost {
    allMarkdownRemark(filter: { fields: { type: { eq: "blog" } } }) {
      nodes {
        ...BlogPostCardFragment
      }
    }
  }
`;
