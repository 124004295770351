import { SiteButton, SiteButtonType } from "../SiteButton";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const BlogPostCard = ({
  content,
}: {
  content?: Queries.BlogPostCardFragmentFragment;
}) => {
  return (
    <article className="grid grid-cols-1 md:grid-cols-2 md:h-72 p-3 gap-2 bg-white drop-shadow-2xl rounded">
      <div className="gap-2 flex flex-col justify-between">
        <h2 className="text-2xl">{content?.frontmatter?.title}</h2>
        <p>{content?.frontmatter?.subtitle}</p>
        <div className="gap-2 flex-col md:flex-row flex items-center md:gap-6">
          <SiteButton
            to={content?.fields?.slug || "/"}
            type={SiteButtonType.READ}
          >
            Read
          </SiteButton>
          <p className="text-gray-500">
            About a {content?.timeToRead} minute read
          </p>
        </div>
      </div>
      {content?.frontmatter?.image?.file?.childImageSharp?.gatsbyImageData && (
        <GatsbyImage
          image={
            content?.frontmatter?.image?.file?.childImageSharp?.gatsbyImageData
          }
          alt={content?.frontmatter.image.alt || ""}
          className="h-20 md:h-full md:h-unset rounded"
        />
      )}
    </article>
  );
};

export { BlogPostCard };
